import React from "react";
import { Link, graphql } from "gatsby";
import { FormattedMessage } from "react-intl";
import Img from "gatsby-image";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ServicesGrid from "../components/services-grid";

const SpiritsTemplate = ({ data, pageContext: context }) => {
  const { title, main_title } = data.contentfulPage;
  const allPages = data.allContentfulPage.nodes;

  function getParentSlug() {
    return allPages.find((page) => page.category === "Esprits").slug;
  }

  return (
    <Layout>
      <Seo title={title} description={main_title} />
      <section className="section section-white">
        <div className="introduction introduction-clouds">
          <div className="introduction-container">
            <div className="introduction-inner">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <p className="eyebrow">{title}</p>
                    <h1>{main_title}</h1>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-gradient">
        <div className="container">
          <h2 className="paragraph font-weight-bold text-yellow text-center mb-10 mb-md-20">
            <FormattedMessage id="spirit.rooms" />
          </h2>
          <div className="columns">
            <div className="columns-item columns-item-image">
              {data.contentfulEsprit.thumbnail && (
                <figure className="position-relative">
                  <Img
                    fluid={{
                      ...data.contentfulEsprit.thumbnail.fluid,
                      aspectRatio: 460 / 500,
                    }}
                    alt={data.contentfulEsprit.title}
                  />
                  {data.contentfulEsprit.logo && (
                    <Img
                      className="logo-absolute"
                      fluid={data.contentfulEsprit.logo.fluid}
                      alt={data.contentfulEsprit.title}
                    />
                  )}
                </figure>
              )}
            </div>
            <div className="columns-item columns-item-text">
              <h3 className="h2">{data.contentfulEsprit.main_title}</h3>
              <p>{data.contentfulEsprit.short_description}</p>
              <p className="mt-md-8">
                <Link
                  to={`/${context.locale}/${getParentSlug()}/${
                    data.contentfulEsprit.slug
                  }/`}
                >
                  <FormattedMessage id="more" />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-blue">
        <div className="container">
          <h2 className="paragraph font-weight-bold text-yellow text-center mb-10 mb-md-20">
            <FormattedMessage id="all-services" />
          </h2>
          <ServicesGrid />
        </div>
      </section>
    </Layout>
  );
};

export default SpiritsTemplate;

export const query = graphql`
  query ($id: String!, $slug: String!, $locale: String!) {
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(
      contentful_id: { eq: "7Ghq3MGGxup1gZhcPpUnH" }
      node_locale: { eq: $locale }
    ) {
      meta_description
      hero_image {
        file {
          url
        }
      }
    }

    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      main_title
    }
    contentfulEsprit(
      contentful_id: { eq: "7espJ6LdiBAZKMMNoQBmgv" }
      node_locale: { eq: $locale }
    ) {
      title
      main_title
      short_description
      slug
      logo {
        fluid(maxWidth: 100, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      thumbnail {
        fluid(maxHeight: 500, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      icon_gallery {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
        title
        description
      }
    }
    contentfulComparatifTableau {
      content {
        title
        icon {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        simplement_bien
        simplement_bien_content
        travel
        travel_content
      }
    }
  }
`;
